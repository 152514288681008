export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER'
export const GET_USER = 'GET_USER';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_BANKS = 'GET_BANKS';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_OVERVIEW = 'GET_OVERVIEW';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const GET_SYSTEM_CONFIG = 'GET_SYSTEM_CONFIG';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDER = 'GET_PROVIDER';

export const GET_VACE_GRAPH = 'GET_VACE_GRAPH';
export const GET_AUDITS = 'GET_AUDITS';
export const GET_AUDIT = 'GET_AUDIT';
export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESSES = 'GET_BUSINESSES';
export const GET_BUSINESS_SETTINGS = 'GET_BUSINESS_SETTINGS';
export const GET_USERS = 'GET_USERS';
export const GET_ADMINS = 'GET_ADMINS';
export const GET_WRITERS = 'GET_WRITERS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_KYB_LIST = 'GET_KYB_LIST';
export const GET_KYB = 'GET_KYB';
export const GET_KYC_LIST = 'GET_KYC_LIST';
export const GET_KYC = 'GET_KYC';

export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GET_SETTLEMENT = 'GET_SETTLEMENT';
export const GET_KYC_DATA = 'GET_KYC_DATA';
export const GET_VERIFICATION_DATA = 'GET_VERIFICATION_DATA';
export const GET_KYB_DATA = 'GET_KYB_DATA';
export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const GET_BENEFICIARY = 'GET_BENEFICIARY';
export const GET_BUSINESS_BANKS = 'GET_BUSINESS_BANKS';
export const GET_BUSINESS_BANK = 'GET_BUSINESS_BANK';
export const GET_WALLET = 'GET_WALLET';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_VACE_OVERVIEW = 'GET_VACE_OVERVIEW';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_ARCHIVED = 'GET_ARCHIVED';
export const GET_BILLERS = 'GET_BILLERS';
export const GET_BILLER = 'GET_BILLER';
export const GET_BILL_CATEGORIES = 'GET_BILL_CATEGORIES';
export const GET_MOBILE_PLANS = 'GET_MOBILE_PLANS';
export const GET_NETWORKS = 'GET_NETWORKS';
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const SET_SPLITS = 'SET_SPLITS';
export const GET_PAYMENT_LINKS = 'GET_PAYMENT_LINKS'
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK'
export const GET_CHARGEBACKS = 'GET_CHARGEBACKS';
export const GET_CHARGEBACK = 'GET_CHARGEBACK';
export const GET_REFUNDS = 'GET_REFUNDS';
export const GET_REFUND = 'GET_REFUND';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE = 'GET_INVOICE';
export const SET_INVOICE_ITEMS = 'SET_INVOICE_ITEMS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST';
export const GET_DEFAULT_PERMISSIONS = 'GET_DEFAULT_PERMISSIONS';
export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_HISTORIES = 'GET_HISTORIES';
export const GET_HISTORY = 'GET_HISTORY';

// generics
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_WALLET = 'SET_WALLET';
export const SET_BANK = 'SET_BANK';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_COUNT = 'SET_COUNT';
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_USERTYPE = 'SET_USERTYPE';
export const SET_RESPONSE = 'SET_RESPONSE';
export const SET_IS_SUPER = 'SET_IS_SUPER';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_MENU_LIST = 'SET_MENU_LIST';
export const SET_USER = 'SET_USER';
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_CURRENCY = 'SET_CURRENCY';